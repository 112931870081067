import { APP_INITIALIZER, Injector, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { BaseComponent } from './shared/component/base/base.component';
import { AuthService } from './feature-module/authentication/service/auth.service';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { JWTInterceptor } from './shared/interceptors/jwt/jwt.interceptor';
// import {DATA_COLLECTION_ENABLED, INSTRUMENTATION_ENABLED} from '@angular/fire/compat/performance';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Capacitor } from '@capacitor/core';
import { provideImgixLoader } from '@angular/common';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { ToastrModule } from 'ngx-toastr';
import { getMessaging, isSupported, Messaging, provideMessaging } from '@angular/fire/messaging';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { MessagingService } from './shared/services/messaging/messaging.service';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeRo from '@angular/common/locales/ro';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { CustomTranslateHttpLoader } from './shared/services/customer-translate-http-loader/customer-translate-http-loader';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeRo, 'ro');

const cookieConsent: NgcCookieConsentConfig = {
  cookie: {
    domain: isDevMode() ? "localhost" : "expertica.ro"
  },
  position: "bottom-right",
  theme: "classic",
  palette: {
    popup: {
      background: "#084aad",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: "#ffde58",
      text: "#000000",
      border: "transparent"
    }
  },
  type: "info",
  content: {
    message: "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
    deny: "Refuse cookies",
    link: "Learn more",
    href: "https://expertica.ro/terms-condition",
    policy: "Cookie Policy"
  }
};

export function messagingInitializer(injector: Injector) {
  return async () => {
    try {
      const supported = await isSupported();
      const messagingService = injector.get(MessagingService);
      if (supported) {
        console.log('Messaging is supported');
        const messaging = injector.get(Messaging);
        messagingService.init(messaging); // Pass Messaging instance if needed
      } else {
        console.log('Messaging is NOT supported');
        messagingService.init(null); // Pass Messaging instance if needed
      }
    } catch (error) {
      console.error('Error checking if messaging is supported:', error);
    }
  };
}

function initApp() {
  return () => {
    return new Promise((resolve) => {
      getAuth().onAuthStateChanged(user => {
        if (user) {
          // console.log('user defined initApp', user);
          resolve({});
        } else {
          console.log('user not defined initApp');
          resolve({});
        }
      });
    });
  }
}

function HttpLoaderFactory(http: HttpClient) {
  const cloudStorageUrl = 'https://firebasestorage.googleapis.com/v0/b/expertica-176ba.appspot.com/o/resources%2Fi18n%2F'; // The base URL for your Cloud Storage bucket
  return new CustomTranslateHttpLoader(http, cloudStorageUrl, '.json?alt=media');
}

@NgModule({ declarations: [
    AppComponent,
    BaseComponent
  ],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConsent),
    ToastrModule.forRoot({
      timeOut: 5000,
      // positionClass: 'toast-bottom-right',
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'increasing',
      tapToDismiss: true
    }),
    // AuthModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en', // this name need to be the same as the JSON file
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    provideFirebaseApp(() => {
      const app = initializeApp(environment.firebaseConfig);
      if (Capacitor.isNativePlatform && Capacitor.getPlatform() !== 'web') {
        initializeAuth(app,{
          persistence: indexedDBLocalPersistence
        });
      }
      return app;
    }),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions(getApp(), 'europe-west6')),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    providePerformance(() => getPerformance(getApp())),
    provideMessaging(() => getMessaging()),
    provideAnalytics(() => getAnalytics(getApp())),
    provideRemoteConfig(() => getRemoteConfig()),
    ScreenTrackingService,
    UserTrackingService,
    // {provide: DATA_COLLECTION_ENABLED, useValue: true},
    // {provide: INSTRUMENTATION_ENABLED, useValue: true},
    {
      provide: APP_INITIALIZER,
      useFactory: messagingInitializer,
      deps: [Injector],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AuthService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true
    },
    AuthService,
    TranslatePipe,
    provideHttpClient(withInterceptorsFromDi()),
    provideImgixLoader('https://expertica-176ba.web.app/')
  ]
})
export class AppModule { }
