import { Component, OnInit } from '@angular/core';
import { trace, Performance } from '@angular/fire/performance';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from './shared/services/categories/categories.service';
import { LocaleService } from './shared/services/locale/locale.service';
import { SubscriptionsPlanService } from './shared/services/subscriptions-plan/subscriptions-plan.service';
import { RemoteConfigService } from './shared/services/remote-config/remote-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private performance: Performance,
              private categoriesService: CategoriesService,
              private subscriptionsPlanService: SubscriptionsPlanService,
              private remoteConfigService: RemoteConfigService,
              private localeService: LocaleService,
              private translateService: TranslateService) { }

  ngOnInit(): void {
    const traceInstance = trace(this.performance, 'appComponentOnInit');
    traceInstance.start();

    this.categoriesService.init();
    this.subscriptionsPlanService.fetchSubscriptionsPlan();

    traceInstance.stop();

    this.translateService.setDefaultLang('en');
  }

}
