import { Injectable } from '@angular/core';
import { CategoriesStore } from '../../stores/categories/categories.store';
import { BaseComponent } from '../../component/base/base.component';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs';
import { buildApiUrl } from '../../utils/helper';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends BaseComponent {

  constructor(private categoriesStore: CategoriesStore,
              private http: HttpClient) {
    super();
  }

  init() {
    this.fetchCategories();
  }

  private fetchCategories() {
    // console.log('Fetching categories');
    this.categoriesStore.setLoading(true);
    this.http.get(buildApiUrl(`categories`))
      .pipe(take(1))
      .subscribe((categories: any) => {
        this.categoriesStore.set(categories);
        this.categoriesStore.setLoading(false);
      }, (error) => {
        this.categoriesStore.setError(error);
        this.categoriesStore.setLoading(false);
      });
  }

}
