import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export class CustomTranslateHttpLoader extends TranslateHttpLoader {
  constructor(http: HttpClient, prefix: string, suffix: string) {
    super(http, prefix, suffix);
  }

  override getTranslation(lang: string) {
    // Append the ?alt=media query parameter to the URL for Firebase Storage
    const customUrl = `${lang}`;
    return super.getTranslation(customUrl);
  }
}
